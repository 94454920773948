<template>
  <transition name="modal">
    <div class="activity-mask fixed top-0 left-0 w-full h-full table" @click="$emit('close')">
      <div class="activity-wrapper">
        <div ref="activityContainer"
             class="modal-container rounded m-auto bg-white"
             style="width: 95%; height: 95%" @click.stop>
          <div class="sticky top-0 p-6 -ml-4 flex justify-between">
            <div class="ml-4 mt-4">
              <h3 class="text-lg leading-6 font-medium text-gray-900 uppercase">USAConnect Help/About</h3>
            </div>
            <div class="ml-4 mt-4 flex-shrink-0">
              <button type="button" class="relative inline-flex items-center px-3 py-1 border border-transparent
                  shadow-sm font-normal rounded bg-gray-700 hover:bg-gray-800 text-white focus:outline-none
                  " @click="$emit('close')">
                <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
                Close
              </button>
            </div>
          </div>
          <div class="relative">
            <div class="absolute inset-x-6 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300"></div>
            </div>
          </div>
          <div class="px-6 py-0 absolute top-32 bottom-10 overflow-auto" style="width: 95%">
            <div class="min-h-screen bg-gray-100 -mt-4 flex flex-col justify-center sm:py-0">

              <div class="relative py-3 sm:max-w-xl sm:mx-auto">

                 <div class="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                  <div class="max-w-md mx-auto">

                    <div>
                      <img v-bind:src="logoImage" class="h-14 sm:h-8" />
                    </div>
                    <div class="divide-y divide-gray-200">
                      <div class="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                        <p>USAConnect is powered by USA Central Station.</p>
                        <div class="flex flex-auto justify-center space-x-4">
                          <a href="tel:18004222300" target="_blank" type="button" class="inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-usa-blue hover:bg-usa-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#fff">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                            </svg>
                          </a>
                          <a href="mailto:dealerservices@usacs.net" target="_blank" type="button" class="inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-usa-blue hover:bg-usa-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#fff">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                            </svg>
                          </a>
                          <a href="https://usacentralstation.com" target="_blank" type="button" class="inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-usa-blue hover:bg-usa-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#fff">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                            </svg>
                          </a>
                        </div>
                        <p>
                          This app allows contacts associated with the security system at a residence or business to interact with each other with chat, and to respond to system events easily through actions in the action-bar. <br />
                          Here are some helpful descriptions of what each action is:
                        </p>
                        <ul class="list-disc space-y-2">
                          <li class="flex items-start">
                            <span class="h-6 flex items-center sm:h-7">
                              <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 h-5 w-5 text-usa-blue-light" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                              </svg>
                            </span>
                            <p class="ml-2"><strong>Event Info:</strong> View information regarding the notification that was received in the SMS message. This will provide a summary of actions for the event.</p>
                          </li>
                          <li class="flex items-start">
                            <span class="h-6 flex items-center sm:h-7">
                              <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 h-5 w-5 text-usa-blue-light" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M0,4.1a.61.61,0,0,0,.32.53l2,1V7.29l-2,1A.61.61,0,0,0,0,8.83v2.34a.61.61,0,0,0,.32.53l2,1v1.62l-2,1a.58.58,0,0,0-.32.52V18.2a.58.58,0,0,0,.32.52l2.34,1.21a.56.56,0,0,0,.54,0l2.34-1.21a.58.58,0,0,0,.32-.52V15.86a.58.58,0,0,0-.32-.52l-2-1V12.71l2-1a.61.61,0,0,0,.32-.53V8.83a.61.61,0,0,0-.32-.53l-2-1V5.64l2-1a.61.61,0,0,0,.32-.53V1.76a.61.61,0,0,0-.32-.53L3.19.06a.59.59,0,0,0-.52,0L.32,1.23A.61.61,0,0,0,0,1.76ZM4.69,17.85l-1.76.9-1.76-.9V16.22l1.76-.88,1.76.88Zm0-7-1.76.88-1.76-.88V9.19l1.76-.88,1.76.88ZM1.17,2.12l1.76-.88,1.76.88V3.74l-1.76.88L1.17,3.74Z"/>
                                <path d="M19.41,1.17H7.62a.59.59,0,1,0,0,1.17H19.41a.59.59,0,1,0,0-1.17Z"/>
                                <path d="M7.62,4.69h5.9a.59.59,0,0,0,0-1.17H7.62a.59.59,0,1,0,0,1.17Z"/>
                                <path d="M7.62,11.76h5.9a.59.59,0,0,0,0-1.17H7.62a.59.59,0,1,0,0,1.17Z" />
                                <path d="M13.52,18.79a.59.59,0,0,0,0-1.17H7.62a.59.59,0,1,0,0,1.17Z" />
                                <path d="M19.41,8.24H7.62a.59.59,0,1,0,0,1.17H19.41a.59.59,0,1,0,0-1.17Z" />
                                <path d="M19.41,15.27H7.62a.59.59,0,0,0,0,1.18H19.41a.59.59,0,0,0,0-1.18Z" />
                                <path d="M3.51,2.93a.59.59,0,1,1-.58-.59A.59.59,0,0,1,3.51,2.93Z" />
                                <path d="M3.51,10a.59.59,0,1,1-.58-.59A.59.59,0,0,1,3.51,10Z" />
                                <path d="M3.51,17a.59.59,0,1,1-1.17,0,.59.59,0,0,1,1.17,0Z"/>
                              </svg>
                            </span>
                            <p class="ml-2"><strong>Monitoring History:</strong> View all of the signals, operator
                              actions, and system events from the Monitoring Center for your account. This displays
                              the most recent records at the top, and as you scroll down they go back in time.
                            </p>
                          </li>
                          <li class="flex items-start">
                            <span class="h-6 flex items-center sm:h-7">
                              <svg class="flex-shrink-0 h-5 w-5 text-usa-blue-light" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                              </svg>
                            </span>
                            <p class="ml-2"><strong>Acknowledge:</strong> This action confirms that you have been made aware of the event at the alarm system location. This will close the event with the monitoring center and you will be able to place the system on-test or request service.</p>
                          </li>
                          <li class="flex items-start">
                          <span class="h-6 flex items-center sm:h-7">
                            <svg class="flex-shrink-0 h-5 w-5 text-usa-blue-light" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M3.707 2.293a1 1 0 00-1.414 1.414l6.921 6.922c.05.062.105.118.168.167l6.91 6.911a1 1 0 001.415-1.414l-.675-.675a9.001 9.001 0 00-.668-11.982A1 1 0 1014.95 5.05a7.002 7.002 0 01.657 9.143l-1.435-1.435a5.002 5.002 0 00-.636-6.294A1 1 0 0012.12 7.88c.924.923 1.12 2.3.587 3.415l-1.992-1.992a.922.922 0 00-.018-.018l-6.99-6.991zM3.238 8.187a1 1 0 00-1.933-.516c-.8 3-.025 6.336 2.331 8.693a1 1 0 001.414-1.415 6.997 6.997 0 01-1.812-6.762zM7.4 11.5a1 1 0 10-1.73 1c.214.371.48.72.795 1.035a1 1 0 001.414-1.414c-.191-.191-.35-.4-.478-.622z" />
                            </svg>
                          </span>
                            <p class="ml-2"><strong>On Test:</strong> This action will place the entire site on-test for a 2 hour period. Events received during this 'test' period will not be acted on by the monitoring center.</p>
                          </li>
                          <li class="flex items-start">
                            <span class="h-6 flex items-center sm:h-7">
                              <svg class="flex-shrink-0 h-5 w-5 text-usa-blue-light" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd" />
                              </svg>
                            </span>
                            <p class="ml-2"><strong>Do Not Dispatch:</strong> This action should be used when the alarm system went into alarm, but all is OK and assistance is not needed, or it was a false alarm. The event will be cleared and no action will be taken by the monitoring center.</p>
                          </li>
                          <li class="flex items-start">
                            <span class="h-6 flex items-center sm:h-7">
                              <svg class="flex-shrink-0 h-5 w-5 text-usa-blue-light" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                              </svg>
                            </span>
                            <p class="ml-2"><strong>Dispatch:</strong> This action should be used when an authority dispatch to the location is needed. The monitoring center will request the appropriate authority to respond to the event.</p>
                          </li>
                          <li class="flex items-start">
                              <span class="h-6 flex items-center sm:h-7">
                                <svg class="flex-shrink-0 h-5 w-5 text-usa-blue-light" viewBox="0 0 20 20" fill="currentColor">
                                  <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                                </svg>
                              </span>
                            <p class="ml-2"><strong>Service:</strong> This action will send a service request email to the alarm dealer/installer with details of the current event.</p>
                          </li>
                          <li class="flex items-start">
                            <span class="h-6 flex items-center sm:h-7">
                              <svg class="flex-shrink-0 h-5 w-5 text-usa-blue-light" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                              </svg>
                            </span>
                            <p class="ml-2"><strong>Call Dealer:</strong> This will call the alarm dealer/installer phone number.</p>
                          </li>
                        </ul>
                        <p>If you would like to be removed from receiving text messages from the USAConnect service, please contact your alarm dealer.</p>
                        <div class="flex justify-center">
                          <p>Version: 1.3.6</p>
                        </div>
                      </div>
                      <div class="pt-6 text-base leading-6 font-bold sm:text-lg sm:leading-7">
                        <p>We would love to hear your feedback:</p>
                        <p>
                          <a href="mailto:support@kirkwork.co" target="_blank" class="text-usa-blue hover:text-usa-blue-light"> Let us know what you think &rarr; </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "HelpOverlay",
  props:['logoImage']
}
</script>

<style>
.activity-mask {
  z-index: 9998;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s ease;
}
.activity-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>