<template>
  <div id="topPanel" class="absolute top-0 left-0 right-0 h-32 bg-gradient-to-r from-gray-300 to-gray-100
              pt-2 lg:pt-0 border-b border-gray-400">
    <div class="flex justify-center flex-nowrap overflow-x-auto">
      <div class="flex-none text-center">
        <button v-on:click="$emit('click-refresh')" class="max-h-8 flex-none inline-block
         justify-center align-middle mx-2 my-1 py-1 px-2 bg-gray-500 hover:bg-gray-600 rounded
         text-white focus:outline-none">
          <svg class="fill-current inline-block align-middle -mt-1 w-4 h-4"
               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path d="M6.32,23.1a12,12,0,0,0,20.86-2.73l3.72,1.46A16,16,0,0,1,4,26.58v3.67H0V19.1H10.27v4ZM32,
            2V13.1H21.73v-4h4.09a12,12,0,0,0-21,2.53L1.1,10.17A16,16,0,0,1,28,5.42V2Z"/></svg>
        </button>
      </div>
      <div class="flex-1 text-center">
        <span class="block uppercase font-light text-sm text-gray-600">{{ alarmInfo.sg_name != null ? alarmInfo.sg_name : 'USACONNECT' }}</span>
        <span class="block truncate">{{ room.name }}</span>
        <div class="flex justify-center flex-wrap">
          <div v-for="x in xmitArray" class="inline-block rounded-full text-sm mt-1 px-3"
               v-bind:class="statusColor(x.status)">
              {{ x.xmit }}: {{ x.status }}
          </div>
        </div>
      </div>
      <div class="flex-none text-center">
        <button v-on:click="$emit('click-help')" class="max-h-8 flex-none inline-block
         justify-center align-middle mx-2 my-1 py-1 px-2 bg-blue-500 hover:bg-blue-600 rounded
         text-white focus:outline-none">
          <svg class="fill-current inline-block align-middle -mt-1 w-4 h-4"
               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                d="M11.36,12.6c.88-1.86,3.25-3.2,6-3.2,3.53,0,6.4,2.15,6.4,4.8,0,2.24-2,4.12-4.81,
                4.65A1.87,1.87,0,0,0,17.4,20.6m0,4.8h0m14.38-8A14.4,14.4,0,1,1,17.4,3,14.4,14.4,0,0,1,31.8,17.4Z"
                transform="translate(-1.4 -1.4)" fill="none" stroke="#fff" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="3.2"
            ></path>
          </svg>
        </button>
      </div>
    </div>
    <div class="flex">
      <div class="flex flex-grow justify-center">
        <div class="flex flex-grow justify-center text-sm overflow-x-auto mx-1 my-1">
<!--          No primary actions available -->
          <div v-if="(allowedActions && allowedActions.primaryActions && !allowedActions.primaryActions.allowAck &&
           !allowedActions.primaryActions.allowDispatch && !allowedActions.primaryActions.allowIgnore) || allowedActions == null" class="py-2 pr-3">
            <span>
              No action required, additional options ➡️
            </span>
          </div>
          <button v-if="allowedActions && allowedActions.primaryActions &&  allowedActions.primaryActions.allowAck"
                  v-on:click="$emit('click-ack')"
                  class="max-h-8 flex-none inline-block justify-center align-middle mx-2 my-1 py-1 px-2 bg-green-700
                 hover:bg-green-800 rounded text-white focus:outline-none">
            <svg class="fill-current inline-block align-middle -mt-1 w-4
                    h-4 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <path d="M12.6,17.4l3.2,3.2,6.4-6.4m9.6,3.2A14.4,14.4,0,1,1,17.4,3,14.4,14.4,0,0,1,31.8,17.4Z"
                    transform="translate(-1.4 -1.4)" fill="none" stroke="#fff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="3.2"/>
            </svg>
            <span>Acknowledge</span>
          </button>
          <button v-if="allowedActions && allowedActions.primaryActions &&  allowedActions.primaryActions.allowIgnore"
                  v-on:click="$emit('click-ignore')"
                  class="max-h-8 flex-none inline-block justify-center align-middle mx-2 my-1 py-1 px-2 bg-green-700
                 hover:bg-green-800 rounded text-white focus:outline-none">
            <svg class="fill-current inline-block align-middle -mt-1 w-4
                    h-4 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <path d="M27.58,27.58A14.4,14.4,0,0,0,7.22,7.22M27.58,27.58A14.4,14.4,0,0,1,7.22,7.22M27.58,27.58,7.22,7.22"
                    transform="translate(-1.4 -1.4)" fill="none" stroke="#fff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="3.2"/>
            </svg>
            <span>Do Not Dispatch</span>
          </button>
          <button v-if="allowedActions && allowedActions.primaryActions &&  allowedActions.primaryActions.allowDispatch"
                  v-on:click="$emit('click-dispatch')"
                  class="max-h-8 flex-none inline-block justify-center align-middle mx-2 my-1 py-1 px-2 bg-red-700
               hover:bg-red-800 rounded text-white focus:outline-none">
            <svg class="fill-current inline-block align-middle -mt-1 w-4 h-4
                  mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <path d="M17.47,12.67V15.9m0,6.45h0M6.3,28.8H28.64A3.23,3.23,0,0,0,31.43,24L20.26,4.61a3.22,3.22,0,0,
          0-5.58,0L3.51,24A3.22,3.22,0,0,0,6.3,28.8Z"
                    transform="translate(-1.46 -1.39)" fill="none" stroke="#fff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="3.22"/>
            </svg>
            <span>Dispatch</span>
          </button>

        </div>
      </div>
      <div class="flex flex-none">
        <button v-on:click="$emit('click-menu')" class="w-8 h-8 flex-none inline-block justify-center
        align-middle mx-2 my-1 py-1 px-1 bg-gray-300 hover:bg-gray-400 rounded text-white focus:outline-none">
          <svg class="fill-current inline-block align-middle -mt-1 ml-2 -mr-1 w-4 h-4"
               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path d="M12.78,5.78v0m0,12.42v0m0,12.43h0m0-23.12a1.78,1.78,0,1,1,1.78-1.78A1.78,1.78,0,0,1,12.78,
            7.56Zm0,12.44a1.78,1.78,0,1,1,1.78-1.78A1.78,1.78,0,0,1,12.78,20Zm0,12.44a1.78,1.78,0,1,1,1.78-1.77A1.78,
            1.78,0,0,1,12.78,32.44Z" transform="translate(-9.22 -2.22)" fill="none" stroke="#000"
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="3.56"/>
          </svg>
        </button>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    props: ['room', 'user', 'contacts', 'alarmInfo', 'xmitArray', 'allowedActions'],
    methods: {
      statusColor: function (status) {
        switch (status.toLowerCase()) {
          case "normal":
            return "bg-green-400"
          case "test":
            return "bg-yellow-400"
          case "alarm":
            return "bg-red-400"
          case "partial test":
            return "bg-orange-400"
          default:
            return "bg-gray-600"
        }
      }
    }
  }
</script>

