<template>
  <div class="flex justify-center h-12 py-2">
    <!-- TODO: Remove hard-coding of color. -->
    <ContactBubble v-for="contact in connectedContacts"
                   v-bind="{contact: contact, color: chatColors[contact.id]}"
                   v-bind:class="{ 'pulsate': (typingTimers[contact.id] > 0) }"
                   class="mx-2">
    </ContactBubble>
  </div>
</template>

<script>
  import ContactBubble from './ContactBubble'
  export default {
    props: ['chatColors', 'connectedContacts', 'typingTimers'],
    components: {
      ContactBubble
    }
  }
</script>
