<template>
  <div>
    <slot name="timestamp">
    </slot>
    <div v-if="message.contact_id == null" class="flex my-3 justify-center">
      <div class="mx-2 p-2 bg-white max-w-full text-gray-800 rounded-lg break-words italic">
        {{ message.body }}
      </div>
    </div>
    <div v-else-if="message.contact_id == viewerId" class="flex items-end ml-12 my-3 flex-row-reverse">
      <ContactBubble v-bind="{contact: this.contact, color: color}"></ContactBubble>
      <div :class="color" class="mx-2 p-2 max-w-full text-white rounded-lg text-right break-words">
        {{ message.body }}
      </div>
    </div>
    <div v-else class="flex my-3 mr-12 items-end flex-row">
      <ContactBubble v-bind="{contact: this.contact, color: color}"></ContactBubble>
      <div :class="color" class="mx-2 p-2 max-w-full text-white rounded-lg break-words">
        {{ message.body }}
      </div>
    </div>
  </div>
</template>

<script>
  import ContactBubble from './ContactBubble'
  export default {
    props: ['message', 'viewerId', 'color', 'contacts'],
    components: {
      ContactBubble
    },
    computed: {
      contact: function() {
        return this.contacts.find(c => c.id == this.message.contact_id);
      }
    }
  }
</script>