<template>
  <div ref="messageList" class="absolute overflow-auto left-0 right-0 p-4 pb-8 top-32">
    <Message v-for="(message, index) in messages"
             v-bind="{ message: message,
                       contacts: contacts,
                       color: chatColors[message.contact_id] }">
      <template v-slot:timestamp>
        <div v-show="shouldShowTimestampBetweenMessages(message, messages[messages.indexOf(message) - 1])"
             class="flex my-3 justify-center">
          <div class="mx-2 p-2 bg-white max-w-full text-gray-500 rounded-lg break-words">
            {{ timestampForMessageAfterMessage(message, messages[messages.indexOf(message) - 1 ]) }}
          </div>
        </div>
      </template>
    </Message>
  </div>
</template>

<script>
import Message from './Message'
export default {
  data: function() {
    return {
      showNewMessageIndicator: false
    }
  },
  props: ['contacts', 'messages', 'chatColors'],
  components: {
    Message,
  },
  mounted: function() {
    // this.$root.$on('click-message-text-field', this.scrollToBottom);
    // window.addEventListener('load', () => {
    //   this.scrollToBottom();
    // })
    var messageList = this.$refs.messageList;
    const vm = this;
  },
  methods: {
    messageToDate: function (message) {
      return new Date(message.created_at);
    },
    timestampForMessageAfterMessage(message, previousMessage) {
      const formatOptionsFull = {
        year: '2-digit', month: 'numeric',
        day: 'numeric', hour: 'numeric',
        minute: '2-digit'
      };
      const formatOptionsTimeOnly = {hour: 'numeric', minute: '2-digit'};
      // Shows the date in the timestamp only if the previous message
      //   was sent on a different calendar day.
      const date = this.messageToDate(message);
      if (previousMessage == null) {
        // It's the first message, so include the date.
        // Use user's local time zone and hide seconds
        return date.toLocaleString([], formatOptionsFull);
      }
      const previousMessageDate = this.messageToDate(previousMessage);
      if (date.getDate() === previousMessageDate.getDate()) {
        // Use user's local time zone and hide seconds
        return date.toLocaleString([], formatOptionsTimeOnly);
      } else {
        // Use user's local time zone and hide seconds
        return date.toLocaleString([], formatOptionsFull);
      }
    },
    shouldShowTimestampBetweenMessages: function (message, previousMessage) {
      if (previousMessage == null) {
        return true; // It's the first message, so show it.
      } else {
        return (this.secondsBetweenMessages(message, previousMessage) > 59);
      }
    },
    secondsBetweenMessages: function (message, previousMessage) {
      const millisDiff = Math.abs(
          new Date(message.created_at) - new Date(previousMessage.created_at));
      return Math.floor((millisDiff / 1000));
    },
  },
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>