<template>
  <transition name="modal">
    <div class="activity-mask fixed top-0 left-0 w-full h-full table" @click="$emit('close')">
      <div class="activity-wrapper">
        <div ref="activityContainer"
             class="modal-container rounded m-auto bg-white overflow-y-scroll"
             style="width: 95%; height: 95%" @click.stop>
          <div class="sticky top-0 p-6 -ml-4 flex justify-between bg-white z-10 ">
            <div class="ml-4 mt-4">
              <h3 class="text-lg leading-6 font-medium text-gray-900 uppercase">Action Menu</h3>
            </div>
            <div class="ml-4 mt-4 flex-shrink-0">
              <button type="button" class="relative inline-flex items-center px-3 py-1 border border-transparent
                  shadow-sm font-normal rounded bg-gray-700 hover:bg-gray-800 text-white focus:outline-none
                  " @click="$emit('close')">
                <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
                Close
              </button>
            </div>
          </div>
          <div class="relative bg-white">
            <div class="absolute inset-x-6 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300"></div>
            </div>
          </div>
          <div class="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center z-0">
            <div class="relative p-6">
              <p class="mt-0 text-lg text-gray-500">
                Options available in this menu may change due to the current status of the account.
              </p>
              <div class="mt-4 space-y-4">

<!--                Request Dispatch after alarm event - available for 5 minutes after alarm is disregarded -->
                <div v-if="allowedActions && withinFiveMinutes" class="flex">
                  <div class="flex-shrink-0">
                    <button v-on:click="$emit('click-request-dispatch')"  class="flex items-center justify-center
                     h-12 w-12 rounded-xl bg-red-500 text-white hover:bg-red-600 focus:outline-none">
                      <svg class="fill-current inline-block align-middle w-6 h-6"
                           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                        <g>
                          <path class="st0" d="M23,10.3H8.8l-3,14.1H4v6.6h24v-6.6h-1.9L23,10.3z M10.4,12h11L24,24.3H7.8L10.4,12z M26.1,29.2H5.8v-2.9h20.3
		V29.2z"/>
                          <path class="st0" d="M15,1H17v4.2H15V1z"/>
                          <path class="st0" d="M21.1,6.3l2.1-3.7l1.6,1l-2.1,3.7L21.1,6.3z"/>
                          <path class="st0" d="M25.8,10.3l3.7-2.1l1,1.6l-3.7,2.1L25.8,10.3z"/>
                          <path class="st0" d="M27.8,17.9V16H32v1.9H27.8z"/>
                          <path class="st0" d="M7,3.7l1.6-1l2.1,3.7l-1.6,1L7,3.7z"/>
                          <path class="st0" d="M1.6,9.9l1-1.6l3.7,2.1l-1,1.6L1.6,9.9z"/>
                          <path class="st0" d="M0,16.2h4.2v1.9H0V16.2z"/>
                          <path class="st0" d="M15,13.9H17v5.6H15V13.9z"/>
                          <path class="st0" d="M15,20.7H17v1.9H15V20.7z"/>
                        </g>
                    </svg>
                    </button>
                  </div>
                  <div class="ml-4">
                    <dt class="text-lg leading-6 font-medium text-gray-900">
                      Request Authority Dispatch (Only available < 5 min after disregard)
                    </dt>
                    <dd class="mt-0 text-base text-gray-500">
                      Request that the authorities are dispatched to the premises address because assistance is needed!
                    </dd>
                  </div>
                </div>

                <div class="flex">
                  <div class="flex-shrink-0">
                    <button v-on:click="$emit('click-event-info')"  class="flex items-center justify-center
                     h-12 w-12 rounded-xl bg-blue-500 text-white hover:bg-blue-600 focus:outline-none">
                      <svg class="fill-current inline-block align-middle w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                        <path d="M37.33,21.33a16,16,0,1,0,16,16A16,16,0,0,0,37.33,21.33Zm0,29.09A13.09,
                13.09,0,1,1,50.42,37.33,13.09,13.09,0,0,1,37.33,50.42Zm0-17.45a1.45,1.45,0,0,0-1.45,
                1.45V44.61a1.46,1.46,0,0,0,2.91,0V34.42A1.45,1.45,0,0,0,37.33,33Zm0-4.36a1.46,1.46,
                0,1,1-1.45,1.45A1.45,1.45,0,0,1,37.33,28.61Z" transform="translate(-21.33 -21.33)"></path>
                      </svg>
                    </button>
                  </div>
                  <div class="ml-4">
                    <dt class="text-lg leading-6 font-medium text-gray-900">
                      Event Information
                    </dt>
                    <dd class="mt-0 text-base text-gray-500">
                      View a summary of the event and any actions that have occurred with this event.
                    </dd>
                  </div>
                </div>

                <div class="flex">
                  <div class="flex-shrink-0">
                    <button v-on:click="$emit('click-activity')"  class="flex items-center justify-center
                     h-12 w-12 rounded-xl bg-green-500 text-white hover:bg-green-600 focus:outline-none">
                      <svg class="fill-current inline-block align-middle w-6 h-6"
                           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                        <path class="st0" d="M0,6.6c0,0.4,0.2,0.7,0.5,0.8L3.8,9v2.7l-3.2,1.6C0.2,13.4,0,13.8,0,14.1v3.8c0,0.4,0.2,0.7,0.5,0.8l3.2,1.6
              v2.6l-3.2,1.6C0.2,24.7,0,25,0,25.4v3.8c0,0.4,0.2,0.7,0.5,0.8l3.8,1.9C4.4,32,4.5,32,4.7,32s0.3,0,0.4-0.1L8.9,30
              c0.3-0.2,0.5-0.5,0.5-0.8v-3.8c0-0.4-0.2-0.7-0.5-0.8l-3.2-1.6v-2.6l3.2-1.6c0.3-0.2,0.5-0.5,0.5-0.8v-3.8c0-0.4-0.2-0.7-0.5-0.8
              l-3.2-1.6V9l3.2-1.6c0.3-0.2,0.5-0.5,0.5-0.8V2.8c0-0.4-0.2-0.7-0.5-0.8L5.1,0.1C4.8,0,4.5,0,4.3,0.1L0.5,2C0.2,2.1,0,2.5,0,2.8V6.6
              z M7.5,28.6L4.7,30l-2.8-1.5V26l2.8-1.4L7.5,26V28.6z M7.5,17.3l-2.8,1.4l-2.8-1.4v-2.6l2.8-1.4l2.8,1.4V17.3z M1.9,3.4L4.7,2
              l2.8,1.4V6L4.7,7.4L1.9,6V3.4z"/>
                        <path class="st0" d="M31.1,1.9H12.2c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h18.9c0.5,0,0.9-0.4,0.9-0.9S31.6,1.9,31.1,1.9z"/>
                        <path class="st0" d="M12.2,7.5h9.4c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-9.4c-0.5,0-0.9,0.4-0.9,0.9S11.7,7.5,12.2,7.5z"/>
                        <path class="st0" d="M12.2,18.8h9.4c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-9.4c-0.5,0-0.9,0.4-0.9,0.9S11.7,18.8,12.2,18.8z"/>
                        <path class="st0" d="M21.6,30.1c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-9.4c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9H21.6z"/>
                        <path class="st0" d="M31.1,13.2H12.2c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h18.9c0.5,0,0.9-0.4,0.9-0.9S31.6,13.2,31.1,13.2z"/>
                        <path class="st0" d="M31.1,24.4H12.2c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h18.9c0.5,0,0.9-0.4,0.9-0.9S31.6,24.4,31.1,24.4z"/>
                        <path class="st0" d="M5.6,4.7c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C5.2,3.8,5.6,4.2,5.6,4.7z"/>
                        <path class="st0" d="M5.6,16c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C5.2,15.1,5.6,15.5,5.6,16z"/>
                        <path class="st0" d="M5.6,27.2c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C5.2,26.3,5.6,26.7,5.6,27.2z"/>
                      </svg>
                    </button>
                  </div>
                  <div class="ml-4">
                    <dt class="text-lg leading-6 font-medium text-gray-900">
                      Monitoring History
                    </dt>
                    <dd class="mt-0 text-base text-gray-500">
                      View the signal and operator actions at the monitoring center for your account.
                    </dd>
                  </div>
                </div>

                <div v-if="allowedActions && allowedActions.secondaryActions &&  allowedActions.secondaryActions.allowOnTest" class="flex">
                  <div class="flex-shrink-0">
                    <button v-on:click="$emit('click-ontest')"
                            class="flex items-center justify-center h-12 w-12 rounded-xl bg-yellow-600
                            hover:bg-yellow-700 text-white focus:outline-none">
                      <svg class="fill-current inline-block align-middle w-6 h-6"
                           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                        <path d="M27.58,7.22a14.38,14.38,0,0,1,0,20.36m0,0-4.52-4.52m4.52,4.52L31.8,31.8M23.06,11.74a8,8,
                  0,0,1,0,11.32m0,0-4.53-4.53m-6.79,4.53a8,8,0,0,1-2.26-4.53m-2.26,9A14.39,14.39,0,0,1,3.75,12.8m12.52,
                  3.47a1.6,1.6,0,1,1,2.26,2.26m-2.26-2.26L3,3M16.27,16.27l2.26,2.26"
                              transform="translate(-1.4 -1.4)" fill="none" stroke="#fff" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="3.2"/>
                      </svg>
                    </button>
                  </div>
                  <div class="ml-4">
                    <dt class="text-lg leading-6 font-medium text-gray-900">
                      Place Site On-Test
                    </dt>
                    <dd class="mt-0 text-base text-gray-500">
                      Take your system offline, so any new events are not acted on by the monitoring center. NOTE: This
                      is a two hour test period.
                    </dd>
                  </div>
                </div>

                <div v-if="allowedActions && allowedActions.secondaryActions &&  allowedActions.secondaryActions.allowService" class="flex">
                  <div class="flex-shrink-0">
                    <button v-on:click="$emit('click-request-service')"
                            class="flex items-center justify-center h-12 w-12 rounded-xl bg-gray-600
                            hover:bg-gray-700 text-white focus:outline-none">
                      <svg class="fill-current inline-block align-middle w-6 h-6"
                           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                        <path d="M14.72,5.11a2.76,2.76,0,0,1,5.36,0,2.76,2.76,0,0,0,4.12,1.7A2.76,2.76,0,0,1,28,10.6a2.76,
                      2.76,0,0,0,1.7,4.12,2.76,2.76,0,0,1,0,5.36A2.76,2.76,0,0,0,28,24.2,2.76,2.76,0,0,1,24.2,28a2.76,
                      2.76,0,0,0-4.12,1.7,2.76,2.76,0,0,1-5.36,0A2.76,2.76,0,0,0,10.6,28,2.76,2.76,0,0,1,6.81,24.2a2.76,
                      2.76,0,0,0-1.7-4.12,2.76,2.76,0,0,1,0-5.36,2.76,2.76,0,0,0,1.7-4.12A2.76,2.76,0,0,1,10.6,6.81,2.76,
                      2.76,0,0,0,14.72,5.11Z"
                              transform="translate(-1.4 -1.4)" fill="none" stroke="#fff" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="3.2"/>
                        <path d="M22.2,17.4a4.8,4.8,0,1,1-4.8-4.8A4.8,4.8,0,0,1,22.2,17.4Z"
                              transform="translate(-1.4 -1.4)" fill="none" stroke="#fff" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="3.2"/>
                      </svg>
                    </button>
                  </div>
                  <div class="ml-4">
                    <dt class="text-lg leading-6 font-medium text-gray-900">
                      Request Service
                    </dt>
                    <dd class="mt-0 text-base text-gray-500">
                      This will email your alarm dealer with details of this event and let them know you are requesting a service call.
                    </dd>
                  </div>
                </div>

                <div v-if="allowedActions && allowedActions.secondaryActions &&  allowedActions.secondaryActions.allowCallDealer" class="flex">
                  <div class="flex-shrink-0">
                    <button v-on:click="$emit('click-call-dealer')"
                            class="flex items-center justify-center h-12 w-12 rounded-xl bg-indigo-400
                            hover:bg-indigo-500 text-white focus:outline-none">
                      <svg class="fill-current inline-block align-middle w-6 h-6 mr-1"
                           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                        <path d="M23.8,3h8m0,0v8m0-8-9.6,9.6M6.2,3A3.2,3.2,0,0,0,3,6.2V7.8a24,24,0,0,0,24,24h1.6a3.2,3.2,0,0,
            0,3.2-3.2V23.35a1.58,1.58,0,0,0-1.09-1.51l-7.19-2.4a1.61,1.61,0,0,0-1.94.8l-1.81,3.61A17.63,17.63,0,
            0,1,11,15l3.61-1.81a1.61,1.61,0,0,0,.8-1.94L13,4.09A1.58,1.58,0,0,0,11.45,3Z"
                              transform="translate(-1.4 -1.4)" fill="none" stroke="#fff" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="3.2"/>
                      </svg>
                    </button>
                  </div>
                  <div class="ml-4">
                    <dt class="text-lg leading-6 font-medium text-gray-900">
                      Call Alarm Dealer
                    </dt>
                    <dd class="mt-0 text-base text-gray-500">
                      Call your alarm dealer/installer.
                    </dd>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from "moment"
export default {
  name: "ActionMenuOverlay",
  props:['allowedActions'],
  data() {
    return {
      now: Date.now()
    }
  },
  computed: {
    withinFiveMinutes() {
      let tmpNow = this.now;
      if (this.allowedActions.disregardEnteredTime){
        let disregardTime = moment.utc(this.allowedActions.disregardEnteredTime);
        let currentTime = moment.utc();
        if ((currentTime - disregardTime) > 300000) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  },
  created() {
    let self = this
    setInterval(function () {
      self.now = Date.now()
    }, 5000)
  },
}
</script>

<style>
.activity-mask {
  z-index: 9998;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s ease;
}
.activity-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>