<template>
  <div id="app" class="overflow-hidden font-body text-black bg-white lg:text-base h-full h-full w-full">
    <p class="notice"></p>
    <p class="alert"></p>
    <Panel v-bind="{room: room, user: user, contacts: contacts, alarmInfo: alarmInfo, xmitArray: xmitArray, allowedActions: allowedActions}"
           v-on:click-dispatch="showDispatchModal = true"
           v-on:click-ignore="showIgnoreModal = true"
           v-on:click-ack="showAckModal = true"
           v-on:click-help="showHelpOverlay = true"
           v-on:click-menu="showActionMenuOverlay = true"
           v-on:click-refresh="refreshData()" >
    </Panel>
    <MessageList v-bind="{user: user,
                          contacts: contacts,
                          messages: messages,
                          chatColors: this.chatColors,}">
    </MessageList>
    <MessageInput v-bind="{room: room, user: user,
                    chatColors: this.chatColors,
                    connectedContacts: connectedContacts,
                    typingTimers: this.typingTimers}">
    </MessageInput>
    <Modal v-show="showDispatchModal" @close="closeModalsAndClearPIN" >
      <template v-slot:header>
        <h1>Request Authority Dispatch</h1>
      </template>
      <p>Are you sure you want to request authority dispatch?</p>
      <template v-slot:footer>
        <button class="inline-block float-right bg-red-700 hover:bg-red-800 rounded text-white
                       ml-4 my-2 px-3 py-1" @click="dispatch">
          Request Dispatch
        </button>
        <button class="inline-block float-right bg-gray-700 hover:bg-gray-800 rounded text-white
                       ml-4 my-2 px-3 py-1" @click="closeModalsAndClearPIN">
          Cancel
        </button>
      </template>
    </Modal>
    <Modal v-show="showIgnoreModal" @close="closeModalsAndClearPIN">
      <template v-slot:header>
        <h1>Do Not Dispatch/Disregard Event</h1>
      </template>
      <p>This will request that the event be disregarded and no authorities will be dispatched.</p>
      <template v-slot:footer>
        <button class="inline-block float-right bg-red-700 hover:bg-red-800 rounded text-white
                       ml-4 my-2 px-3 py-1" @click="ignore">
          Do Not Dispatch
        </button>
        <button class="inline-block float-right bg-gray-700 hover:bg-gray-800 rounded text-white
                       ml-4 my-2 px-3 py-1" @click="closeModalsAndClearPIN">
          Cancel
        </button>
      </template>
    </Modal>
    <Modal v-show="showAckModal" @close="closeModalsAndClearPIN">
      <template v-slot:header>
        <h1>Acknowledge Event</h1>
      </template>
      <p>This will document that you have been notified of and acknowledged the event.</p>
      <template v-slot:footer>
        <button class="inline-block float-right bg-red-700 hover:bg-red-800 rounded text-white
                       ml-4 my-2 px-3 py-1" @click="ack_event">
          Acknowledge
        </button>
        <button class="inline-block float-right bg-gray-700 hover:bg-gray-800 rounded text-white
                       ml-4 my-2 px-3 py-1" @click="closeModalsAndClearPIN">
          Cancel
        </button>
      </template>
    </Modal>
    <Modal v-show="showOnTestModal" @close="closeModalsAndClearPIN">
      <template v-slot:header>
        <h1>Place On-Test</h1>
      </template>
      <p>Please enter your passcode to place your account on-test (2-hour test period).</p>
      <input class="mt-4 mb-1 appearance-none border rounded w-full py-2 px-3 text-gray-700
                    leading-tight focus:outline-none focus:shadow-outline"
             v-model="pin"
             type="text" placeholder="PIN" />
      <template v-slot:footer>
        <button class="inline-block float-right bg-red-700 hover:bg-red-800 rounded text-white
                       ml-4 my-2 px-3 py-1" @click="ontest">
          Place On-Test
        </button>
        <button class="inline-block float-right bg-gray-700 hover:bg-gray-800 rounded text-white
                       ml-4 my-2 px-3 py-1" @click="closeModalsAndClearPIN">
          Cancel
        </button>
      </template>
    </Modal>
    <Modal v-show="showServiceModal" @close="closeModalsAndClearPIN">
      <template v-slot:header>
        <h1>Request Service</h1>
      </template>
      <p>This will email a service request to your alarm dealer/installer. Continue?</p>
      <template v-slot:footer>
        <button class="inline-block float-right bg-red-700 hover:bg-red-800 rounded text-white
                       ml-4 my-2 px-3 py-1" @click="request_service">
          Yes
        </button>
        <button class="inline-block float-right bg-gray-700 hover:bg-gray-800 rounded text-white
                       ml-4 my-2 px-3 py-1" @click="closeModalsAndClearPIN">
          No
        </button>
      </template>
    </Modal>
    <Modal v-show="showCallDealerModal" @close="closeModalsAndClearPIN">
      <template v-slot:header>
        <h1>Call Alarm Dealer/Installer</h1>
      </template>
      <p>Would you like to call your alarm dealer/installer?</p>
      <template v-slot:footer>
        <a class="inline-block float-right bg-red-700 hover:bg-red-800 rounded text-white
                       ml-4 my-2 px-3 py-1" :href="`tel:${ dealerPhone }`">
          Call
        </a>
        <button class="inline-block float-right bg-gray-700 hover:bg-gray-800 rounded text-white
                       ml-4 my-2 px-3 py-1" @click="closeModalsAndClearPIN">
          No
        </button>
      </template>
    </Modal>
    <ActivityOverlay v-show="showActivityOverlay"
                     v-bind="{siteActivity: siteActivity, historyLoading: historyLoading}"
                     @close="closeModalsAndClearPIN"
                     @refresh-history="getActivity()"
                     @click-history-refresh="loadMoreActivity()">
    </ActivityOverlay>
    <EventInfoOverlay v-show="showEventInfoOverlay"
                     v-bind="{alarmHistorySummary: alarmInfo.alarmHistorySummaryList, displaySummary:alarmInfo.displaySummary}"
                     @close="closeModalsAndClearPIN">
    </EventInfoOverlay>
    <HelpOverlay v-show="showHelpOverlay" @close="closeModalsAndClearPIN"
                 v-bind="{logoImage: logoImage}">
    </HelpOverlay>
    <ActionMenuOverlay v-show="showActionMenuOverlay"
                       v-bind="{allowedActions: allowedActions}"
                       @close="closeModalsAndClearPIN"
                       @click-event-info="viewEventSummary"
                       @click-ontest="showTestConfirm"
                       @click-request-service="showServiceConfirm"
                       @click-call-dealer="showCallDealerConfirm"
                       @click-activity="viewAccountHistory"
                       @click-request-dispatch="showDispatchConfirm">
    </ActionMenuOverlay>
    <SimpleNotification v-show="showNotification"
                        v-bind="{message: notificationMessage}"
                        @close="showNotification = false">

    </SimpleNotification>
  </div>

</template>

<script>
  import Panel from './components/Panel'
  import MessageList from './components/MessageList'
  import MessageInput from './components/MessageInput'
  import Modal from './components/Modal'
  import ActivityOverlay from './components/ActivityOverlay'
  import consumer from 'channels/consumer'
  import axios from 'axios'
  import EventInfoOverlay from "./components/EventInfoOverlay";
  import HelpOverlay from "./components/HelpOverlay";
  import ActionMenuOverlay from "./components/ActionMenuOverlay";
  import SimpleNotification from "./components/SimpleNotification";

  export default {
    props: ['initial_room', 'user', 'contacts', 'xmits', 'old_messages',
            'initial_site_activity', 'alarm_info', 'allowed_actions', 'dealer_phone', 'logo_image', 'active_xmit'],
    data: function() {
      return {
        room: this.initial_room,
        messages: this.old_messages,
        siteActivity: this.initial_site_activity,
        xmitArray: this.xmits,
        activeXmit: this.active_xmit,
        alarmInfo: this.alarm_info,
        allowedActions: this.allowed_actions,
        dealerPhone: this.dealer_phone,
        showDispatchModal: false,
        showIgnoreModal: false,
        showActivityOverlay: false,
        showEventInfoOverlay: false,
        showHelpOverlay: false,
        showActionMenuOverlay: false,
        showOnTestModal:false,
        showServiceModal:false,
        showCallDealerModal:false,
        showAckModal: false,
        historyLoading: false,
        showNotification: false,
        notificationMessage: '',
        pin: '',
        connectedContacts: {},
        typingTimers: {},
        logoImage: this.logo_image,
      }
    },
    computed: {
      colorList: function() {
        // TODO: Maybe this should be declared outside of computed?
        // These are Tailwind color utility classes. See
        //   https://tailwindcss.com/docs/background-color/
        return [
          'bg-pink-700',
          'bg-blue-700',
          'bg-green-700',
          'bg-red-700',
          'bg-purple-700',
          'bg-indigo-700',
          'bg-gray-700',
          'bg-yellow-700',
        ]
      },
      chatColors: function() {
        var contactColors = {};
        this.contacts.forEach((contact, index) => {
          // Select a color for this contact by matching up the indexes of the
          //  contact list and the color list. If there are more contacts than
          //  colors, it "loops back around" to the start of the color list
          //  (via the modulus operator (`%`)).
          contactColors[contact.id] = this.colorList[index % this.colorList.length];
        });
        return contactColors;
      },

    },
    methods: {
      dispatch: function() {
        let self = this;
        // POST body params: xmit, contact_name, alarm_num, pin
        var t_xmit = this.activeXmit;
        var t_alarm_num = this.alarmInfo.alarmNum;
        var t_contact_name = "";
        var contact_rec = this.contacts.find(obj => {
          return obj.num === this.user.num
        })
        if (contact_rec){
          t_contact_name = contact_rec.name;
        }
        const postUrl = '/c/' + this.room.site_num + '/' + this.user.num + '/dispatch/';
        axios.post(postUrl, {
          xmit: t_xmit,
          contact_name: t_contact_name,
          alarm_num: t_alarm_num
        }).then(function (response) {
          // refresh the data
          self.refreshData();
        })
            .catch(function (error) {
              console.log(error);
            });
        this.closeModalsAndClearPIN()
      },
      ignore: function() {
        let self = this;
        // POST body params: xmit, contact_name, alarm_num
        var t_xmit = this.activeXmit;
        var t_alarm_num = this.alarmInfo.alarmNum;
        var t_contact_name = "";
        var contact_rec = this.contacts.find(obj => {
          return obj.num === this.user.num
        })
        if (contact_rec){
          t_contact_name = contact_rec.name;
        }
        const postUrl = '/c/' + this.room.site_num + '/' + this.user.num + '/ignore/';
        axios.post(postUrl, {
          xmit: t_xmit,
          contact_name: t_contact_name,
          alarm_num: t_alarm_num
        }).then(function (response) {
          // refresh the data
          self.refreshData();
        })
            .catch(function (error) {
              console.log(error);
            });
        this.closeModalsAndClearPIN()
      },
      ack_event: function() {
        let self = this;
        // POST body params: xmit, contact_name, alarm_num
        var t_xmit = this.activeXmit;
        var t_alarm_num = this.alarmInfo.alarmNum;
        var t_contact_name = "";
        var contact_rec = this.contacts.find(obj => {
          return obj.num === this.user.num
        })
        if (contact_rec){
          t_contact_name = contact_rec.name;
        }
        const postUrl = '/c/' + this.room.site_num + '/' + this.user.num + '/ack/';
        axios.post(postUrl, {
          xmit: t_xmit,
          contact_name: t_contact_name,
          alarm_num: t_alarm_num
        })
            .then(function (response) {
              // refresh the data
              self.refreshData();
            })
            .catch(function (error) {
              console.log(error);
            });
        this.closeModalsAndClearPIN()
      },
      ontest: function() {
        let self = this;
        // POST body params: xmit, contact_name, alarm_num, pin
        var t_xmit = this.activeXmit;
        var t_alarm_num = this.alarmInfo.alarmNum;
        var t_contact_name = "";
        var contact_rec = this.contacts.find(obj => {
          return obj.num === this.user.num
        })
        if (contact_rec){
          t_contact_name = contact_rec.name;
        }
        var t_pin = this.pin;
        const postUrl = '/c/' + this.room.site_num + '/' + this.user.num + '/ontest/';
        axios.post(postUrl, {
          xmit: t_xmit,
          contact_name: t_contact_name,
          alarm_num: t_alarm_num,
          pin: t_pin
        })
            .then(function (response) {
              console.log(response);
              // refresh the data
              self.refreshData();
        })
          .catch(function (error) {
            console.log(error);
          });
        this.closeModalsAndClearPIN()
      },
      request_service: function() {
        // POST body params: xmit, contact_name, alarm_num, pin
        var t_xmit = this.activeXmit;
        var t_alarm_num = this.alarmInfo.alarmNum;
        var t_contact_name = "";
        var contact_rec = this.contacts.find(obj => {
          return obj.num === this.user.num
        })
        if (contact_rec){
          t_contact_name = contact_rec.name;
        }
        var t_pin = this.pin;
        const postUrl = '/c/' + this.room.site_num + '/' + this.user.num + '/service/';
        axios.post(postUrl, {
          xmit: t_xmit,
          contact_name: t_contact_name,
          alarm_num: t_alarm_num,
          pin: t_pin
        });
        this.closeModalsAndClearPIN()
      },
      checkConnectedContacts: function () {
        const getUrl = '/c/' + this.room.site_num + '/' + 'connected_contacts';
        axios.get(getUrl).then((response) => {
          // TODO: Need handling here for incorrect data. If this loads in
          //   an entire error page render or something, the browser could
          //   lock up and/or crash.
          this.connectedContacts = response.data;
        }, (error) => {
          // TODO: Error handling here?
          console.log('Error checking for connected contacts: ' + error);
        });
      },
      refreshData: function(){
        console.log('refresh_data hit');
        const postUrl = '/c/' + this.room.site_num + '/' + this.user.num + '/refresh/';
        axios.get(postUrl).then((response) => {
          if (response.data){
            var tmpChatInfo = response.data;
            if (tmpChatInfo && tmpChatInfo.alarmNum !== 0)
            {
              tmpChatInfo.alarmInfo.alarmNum = tmpChatInfo.alarmNum;
              this.xmitArray = tmpChatInfo.siteInfo.xmitList;
              console.log('Xmit status = ' + tmpChatInfo.siteInfo.xmitList[0].status);
              this.allowedActions = tmpChatInfo.allowedActions;
              this.alarmInfo = tmpChatInfo.alarmInfo;
              this.alarmInfo.sg_name = tmpChatInfo.siteInfo.siteGroupInfo.siteGroupName;
              this.notificationMessage = 'Data refreshed.';
              this.showNotification = true;
            }
          }
        }, (error) => {
          console.log('Error refreshing data: ' + error);
          this.notificationMessage = 'Error refreshing data.';
          this.showNotification = true;
        });
      },
      updateRoom: function () {
        const getUrl = '/c/' + this.room.site_num + '/' + 'update_room';
        axios.get(getUrl).then((response) => {
          // TODO: Need handling here for incorrect data. If this loads in
          //   an entire error page render or something, the browser could
          //   lock up and/or crash.
          this.room = response.data;
        }, (error) => {
          // TODO: Error handling here?
          console.log('Error updating room: ' + error);
        });
      },
      getActivity: function() {
        this.historyLoading = true;
        const getUrl = '/c/' + this.room.site_num + '/' + 'get_activity';
        axios.post(getUrl,{}).then((response) => {
          // TODO: Need handling here for incorrect data. If this loads in
          //   an entire error page render or something, the browser could
          //   lock up and/or crash.
          console.log("Activity Returned: " + response.data)
          this.siteActivity = response.data;
          this.historyLoading = false;
        }, (error) => {
          // TODO: Error handling here?
          console.log('Error getting site activity: ' + error);
        });
      },
      loadMoreActivity: function(){
        let self = this;
        console.log("Hit load more activity")
        this.historyLoading = true;
        // get the last record of siteActivity array
        if (this.siteActivity && this.siteActivity.length > 0){
          let numRecs = this.siteActivity.length;
          let lastHistRec = this.siteActivity[numRecs - 1];
          if (lastHistRec && lastHistRec.historyDate){
            const postUrl = '/c/' + this.room.site_num + '/' + 'get_activity';
            axios.post(postUrl, {
              history_date: lastHistRec.historyDate,
            }).then(function (response) {
              if (response.data){
                self.loadHistory(response.data);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
          }
        }
      },
      loadHistory: function(hist_data){
        console.log("LoadHistory function called/")
        this.siteActivity = hist_data;
        this.historyLoading = false;
      },
      viewEventSummary: function(){
        console.log("Reached viewEventSummary");
        this.showActionMenuOverlay = false;
        this.showEventInfoOverlay = true;
      },
      viewAccountHistory: function(){
        console.log("Reached viewHistory");
        this.getActivity();
        this.showActionMenuOverlay = false;
        this.showActivityOverlay = true;
      },
      showTestConfirm: function(){
        console.log("Reached showTestConfirm");
        this.showActionMenuOverlay = false;
        this.showOnTestModal = true;
      },
      showDispatchConfirm: function(){
        console.log("Reached showDispatchConfirm");
        this.showActionMenuOverlay = false;
        this.showDispatchModal = true;
      },
      showServiceConfirm: function(){
        console.log("Reached showServiceConfirm");
        this.showActionMenuOverlay = false;
        this.showServiceModal = true;
      },
      showCallDealerConfirm: function(){
        console.log("Reached showCallDealerConfirm");
        this.showActionMenuOverlay = false;
        this.showCallDealerModal = true;
      },
      closeModalsAndClearPIN: function () {
        console.log("close and clear called.")
        this.showActivityOverlay = false;
        this.showDispatchModal = false;
        this.showIgnoreModal = false;
        this.showEventInfoOverlay = false;
        this.showHelpOverlay = false;
        this.showActionMenuOverlay = false;
        this.showOnTestModal = false;
        this.showServiceModal = false;
        this.showCallDealerModal = false;
        this.showAckModal = false;
        this.pin = '';
      },
    },
    components: {
      SimpleNotification,
      Panel,
      MessageList,
      MessageInput,
      Modal,
      ActivityOverlay,
      EventInfoOverlay,
      HelpOverlay,
      ActionMenuOverlay
    },
    created: function() {
      const vm = this;
      // ActionCable subscription
      consumer.subscriptions.create({
          channel: "RoomChannel",
          room_id: this.room.id,
          contact_id: this.user.id
        },
        {
          // REVIEW: Maybe can do something with this.perform
          //   here, to call a method of RoomChannel, to check contacts instead
          //   of making a GET call to a route via checkConnectedContacts().
          //   Of course,
          //   we may need the site_num and contact_num from the params, so...
          received(data) {
            if (data.message) {
              vm.messages.push(data.message);
              vm.checkConnectedContacts();
            }
            else if (data.typing) {
              // Mark the contact as typing for the next few seconds.
              // Need to use $set, as adding a property normally will mean
              //   the property will not be reactive.
              vm.$set(vm.typingTimers, data.typing, 3);
            }
          },
          connected() {
            vm.checkConnectedContacts();
            // I put an event listener for typing here, rather than doing
            //   things through Vue. This is because I need to use perform()
            //   in this scope to call RoomChannel#notify_typing. If there is
            //   a good way to broadcast to ActionCable via JavaScript, without
            //   having to do it through RoomChannel.rb, that would be prefered
            //   and we should switch to that.
            const messageInput = document.getElementById('body');
            const consumer = this;
            messageInput.addEventListener('input', function (evt) {
              // TODO: We should have a timer here or something, so that we only
              //    notify the server about typing every few seconds at most.
              consumer.perform('notify_typing', { contact_id: vm.user.id });
            });
          }
        }
      )
    },
    mounted: function() {
      document.addEventListener('keydown', (e) => {
        if (e.key === "Escape") {
          this.closeModalsAndClearPIN();
        }
      });
      //panelSectionHeight();
      window.setInterval(() => {
        for (const [contact_id, timerValue] of Object.entries(this.typingTimers)) {
          if (timerValue > 0) {
            this.typingTimers[contact_id] = timerValue - 1;
          }
        }
      }, 1000);

      // window.setInterval(() => {
      //   this.updateRoom();
      //   this.getActivity();
      // }, (30 * 1000));
    },
    watch: {
      messages(newValue) {
        console.log(newValue)
        const lastMessage = newValue[newValue.length - 1]
        // check for message of 'UPDATE:'
        if (lastMessage && lastMessage.body){
          var isUpdate = lastMessage.body.startsWith("UPDATE:", 0);
          if (isUpdate){
            this.refreshData();
          }
        }
      }
    }
  }
</script>

<style scoped>
</style>
