import '../css/application.css'
import Vue from 'vue/dist/vue.esm'
import App from '../App.vue'
import MessagesApp from '../MessagesApp.vue'
import smoothscroll from 'smoothscroll-polyfill';

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("axios");

document.addEventListener('DOMContentLoaded', () => {
  // Initialize Vue app
  const appElement = document.getElementById("app")
  if (appElement != null){
    const appProps = JSON.parse(appElement.getAttribute('data'))
    if (appProps != null) {
      const app = new Vue({
        el: '#app',
        render: h => h(App, { props: appProps })
      })
    }
  }
  const messagesAppElement = document.getElementById("messages-app")
  if (messagesAppElement != null){
    const appProps = JSON.parse(messagesAppElement.getAttribute('data'))
    if (appProps != null) {
      const app = new Vue({
        el: '#messages-app',
        render: h => h(MessagesApp, { props: appProps })
      })
    }
  }


  // Initialize polyfill, for smooth scrolling on Safari, IE, and Edge
  smoothscroll.polyfill();
})


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
