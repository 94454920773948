<template>
  <div class="mb-6">
    <p class="text-gray-500 italic">{{ item.historyDate.replace('T', ' ') }}</p>
    <h4>{{ item.point }}</h4>
    <p>{{ item.eventOpAct }}</p>
    <p>{{ item.comment }}</p>
  </div>
</template>

<script>
  export default {
    props: ['item']
  }
</script>
