<template>
  <div id="messages-app" class="overflow-hidden font-body text-black bg-white lg:text-base h-full h-full w-full">
    <p class="notice"></p>
    <p class="alert"></p>
    <MessageHistoryPanel v-bind="{room: room, contacts: contacts, logoImage: logoImage}">
    </MessageHistoryPanel>
    <MessageHistoryList v-bind="{contacts: contacts,
                          messages: messages,
                          chatColors: this.chatColors,}">
    </MessageHistoryList>
  </div>
</template>

<script>
import MessageHistoryPanel from './components/MessageHistoryPanel'
import MessageHistoryList from './components/MessageHistoryList'

export default {
  props: ['initial_room', 'contacts', 'old_messages', 'logo_image'],
  data: function() {
    return {
      room: this.initial_room,
      messages: this.old_messages,
      logoImage: this.logo_image,
    }
  },
  computed: {
    colorList: function() {
      // TODO: Maybe this should be declared outside of computed?
      // These are Tailwind color utility classes. See
      //   https://tailwindcss.com/docs/background-color/
      return [
        'bg-pink-700',
        'bg-blue-700',
        'bg-green-700',
        'bg-red-700',
        'bg-purple-700',
        'bg-indigo-700',
        'bg-gray-700',
        'bg-yellow-700',
      ]
    },
    chatColors: function() {
      var contactColors = {};
      this.contacts.forEach((contact, index) => {
        // Select a color for this contact by matching up the indexes of the
        //  contact list and the color list. If there are more contacts than
        //  colors, it "loops back around" to the start of the color list
        //  (via the modulus operator (`%`)).
        contactColors[contact.id] = this.colorList[index % this.colorList.length];
      });
      return contactColors;
    },

  },
  methods: {

  },
  components: {
    MessageHistoryList,
    MessageHistoryPanel,
  },
  created: function() {
    const vm = this;
    // ActionCable subscription

  },
  mounted: function() {

  },
  watch: {

  }
}
</script>

<style scoped>
</style>
