<template>
  <transition name="modal">
    <div class="activity-mask fixed top-0 left-0 w-full h-full table" @click="$emit('close')">
        <div ref="activityContainer" class="fixed modal-container rounded m-auto bg-white top-6 bottom-6 mx-4
                overflow-hidden" @click.stop>
          <div class="sticky top-0 p-6 -ml-4 flex justify-between">
            <div class="ml-4 mt-4">
              <h3 class="text-lg leading-6 font-medium text-gray-900 uppercase">Event Information</h3>
            </div>
            <div class="ml-4 mt-4 flex-shrink-0">
              <button type="button" class="relative inline-flex items-center px-3 py-1 border border-transparent
                  shadow-sm font-normal rounded bg-gray-700 hover:bg-gray-800 text-white focus:outline-none
                  focus:ring-2 focus:ring-offset-2" @click="$emit('close')">
                <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
                Close
              </button>
            </div>
          </div>
          <div class="flex-col px-6 py-4 overflow-y-auto pb-10 h-full top-2" >
            <p class=" mt-0 text-lg text-indigo-700">SUMMARY:</p>
            <p class="mt-1 pt-1 pb-1 text-lg text-gray-700">{{ displaySummary }}</p>
            <div class="relative pt-1 pb-1">
              <div class="inset-x-6 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300"></div>
              </div>
            </div>
            <p class=" mt-1 text-lg text-indigo-700">DETAILS:</p>
            <slot>
              <div v-for="ahs in alarmHistorySummary" >
                <p>{{ahs.displayEvent}}</p>
              </div>
              <div class="py-36">

              </div>
            </slot>
          </div>
        </div>
      </div>

  </transition>
</template>

<script>
export default {
  props: ['alarmHistorySummary', 'displaySummary']
}
</script>

<style>
.activity-mask {
  z-index: 9998;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s ease;
}
.activity-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>