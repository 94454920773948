<template>
  <div :class="color" class="flex-none uppercase p-2 w-10 h-10 text-white text-center rounded-full">
    {{ initials }}
  </div>
</template>

<script>
  export default {
    props: ['contact', 'color'],
    computed: {
      initials: function() {
        var name = this.contact.name
        var name_split = name.split(' ');
        switch (name_split.length) {
          case 1:
            return name_split[0].charAt(0).toUpperCase();
          default:
            return name_split[0].charAt(0).toUpperCase() + name_split[name_split.length - 1].charAt(0).toUpperCase();
        }
      }
    }
  }
</script>