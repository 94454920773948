<template>
  <div id="topPanel" class="absolute top-0 left-0 right-0 h-32 bg-gradient-to-r from-gray-300 to-gray-100
              pt-2 lg:pt-0 border-b border-gray-400">
    <div class="flex justify-center flex-nowrap overflow-x-auto">
      <div class="flex-1 text-center">
        <img v-bind:src="logoImage" class="mx-auto h-12 lg:h-16 md:h-16 sm:h-16" alt="USAConnect Logo" />
        <span class="block uppercase font-light text-sm text-gray-600">USAConnect - Event Message History</span>
        <span class="block">{{ room.name }}</span>
      </div>
    </div>
    <div class="flex">
      <div class="flex flex-grow justify-center">
        <div class="flex flex-grow justify-center text-sm overflow-x-auto mx-1 my-1">
          <!--          No primary actions available -->

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: ['room', 'contacts', 'logoImage'],
}
</script>

