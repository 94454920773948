<template>
  <div ref="messageList" class="absolute overflow-auto left-0 right-0 p-4 pb-8 top-32 bottom-32">
    <Message :message="warningMessage"></Message>
    <Message v-for="(message, index) in messages"
             v-bind="{ message: message,
                       contacts: contacts,
                       viewerId: user.id,
                       color: chatColors[message.contact_id] }">
      <template v-slot:timestamp>
        <div v-show="shouldShowTimestampBetweenMessages(message, messages[messages.indexOf(message) - 1])"
             class="flex my-3 justify-center">
          <div class="mx-2 p-2 bg-white max-w-full text-gray-500 rounded-lg break-words">
            {{ timestampForMessageAfterMessage(message, messages[messages.indexOf(message) - 1 ]) }}
          </div>
        </div>
      </template>
    </Message>
    <transition name="fade">
      <NewMessageIndicator v-show="showNewMessageIndicator" v-on:click-new-message-indicator="scrollToBottom">
      </NewMessageIndicator>
    </transition>
  </div>
</template>

<script>
  import Message from './Message'
  import NewMessageIndicator from "./NewMessageIndicator"
  export default {
    data: function() {
      return {
        showNewMessageIndicator: false,
        warningMessage : this.defaultMessage()
      }
    },
    props: ['user', 'contacts', 'messages', 'chatColors'],
    components: {
      Message,
      NewMessageIndicator
    },
    mounted: function() {
      this.$root.$on('click-message-text-field', this.scrollToBottom);
      window.addEventListener('load', () => {
        this.scrollToBottom();
      })
      var messageList = this.$refs.messageList;
      const vm = this;
      messageList.addEventListener('scroll', function() {
        if (vm.isScrolledToBottom()) {
          vm.showNewMessageIndicator = false;
        }
      });
    },
    methods: {
      defaultMessage: function () {
        return {
          contact_id : null,
          body : 'Messages are only between contacts on the account. Do not put messages or instructions for the monitoring center here. Reference the action bar above ☝️ for allowed actions.'
        }
      },
      scrollToBottom: function () {
        const messageListDiv = this.$refs.messageList;
        messageListDiv.scrollTo({
          top: messageListDiv.scrollHeight,
          left: 0,
          // Smooth scroll will not work on Safari, Edge, or IE
          //   as of 2020-01-06.
          behavior: 'smooth'
        });
      },
      messageToDate: function (message) {
        return new Date(message.created_at);
      },
      timestampForMessageAfterMessage(message, previousMessage) {
        const formatOptionsFull = {
          year: '2-digit', month: 'numeric',
          day: 'numeric', hour: 'numeric',
          minute: '2-digit'
        };
        const formatOptionsTimeOnly = {hour: 'numeric', minute: '2-digit'};
        // Shows the date in the timestamp only if the previous message
        //   was sent on a different calendar day.
        const date = this.messageToDate(message);
        if (previousMessage == null) {
          // It's the first message, so include the date.
          // Use user's local time zone and hide seconds
          return date.toLocaleString([], formatOptionsFull);
        }
        const previousMessageDate = this.messageToDate(previousMessage);
        if (date.getDate() === previousMessageDate.getDate()) {
          // Use user's local time zone and hide seconds
          return date.toLocaleString([], formatOptionsTimeOnly);
        } else {
          // Use user's local time zone and hide seconds
          return date.toLocaleString([], formatOptionsFull);
        }
      },
      secondsBetweenMessages: function (message, previousMessage) {
        const millisDiff = Math.abs(
          new Date(message.created_at) - new Date(previousMessage.created_at));
        return Math.floor((millisDiff / 1000));
      },
      shouldShowTimestampBetweenMessages: function (message, previousMessage) {
        if (previousMessage == null) {
          return true; // It's the first message, so show it.
        } else {
          return (this.secondsBetweenMessages(message, previousMessage) > 59);
        }
      },
      isScrolledNearBottom: function () {
        var messageList = this.$refs.messageList;
        const toleranceOffset = 100;
        return ((messageList.scrollTop + toleranceOffset) >= (messageList.scrollHeight - messageList.offsetHeight));
      },
      isScrolledToBottom: function () {
        var messageList = this.$refs.messageList;
        return (messageList.scrollTop >= (messageList.scrollHeight - messageList.offsetHeight));
      },
    },
    watch: {
      messages: function () {
        if (this.isScrolledNearBottom()) {
          this.$nextTick(function () {
            this.scrollToBottom();
          });
        }
        else {
          this.showNewMessageIndicator = true;
        }
      }
    }
  }
</script>

<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .25s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>