<template>
  <transition name="modal">
    <div class="activity-mask fixed top-0 left-0 w-full h-full table" @click="$emit('close')">
      <div class="activity-wrapper">
        <div ref="activityContainer"
             class="modal-container rounded m-auto bg-white"
             style="width: 95%; height: 95%" @click.stop>
          <div class="flex flex-auto sticky top-0 h-20 p-6 bg-white">
            <span class="flex-grow mt2 text-lg align-middle uppercase text-gray-800
                         font-normal">
              Monitoring Center Event History
            </span>
            <button class="flex h-8 justify-end bg-green-500 hover:bg-green-600 rounded text-white
                       mx-2 my-2 px-3 py-1 focus:outline-none" @click="$emit('refresh-history')">
              <svg class="w-4 h-4 mt-1"  version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                   viewBox="0 0 20 20" height="20" width="20" xml:space="preserve">
                <g>
                  <g>
                    <path style="fill:#ffffff;" class="st0" d="M4.3,15.7c-3.1-3.1-3.1-8.2,0-11.3L6,6V1H1l1.9,1.9C-1,6.8-1,13.2,2.9,17.1c2.5,2.5,5.9,3.4,9.1,2.7v-2.1
                      C9.3,18.4,6.4,17.7,4.3,15.7z"/>
                  </g>
                </g>
                <g>
                  <g>
                    <path style="fill:#ffffff;" class="st0" d="M17.1,2.9C14.6,0.5,11.2-0.4,8,0.2v2.1c2.7-0.7,5.6,0,7.7,2.1c3.1,3.1,3.1,8.2,0,11.3L14,14v5h5l-1.9-1.9
                      C21,13.2,21,6.8,17.1,2.9z"/>
                  </g>
                </g>
                </svg>
            </button>
            <button class="flex justify-end bg-gray-700 hover:bg-gray-800 rounded text-white
                       mx-2 my-2 px-3 py-1 h-8 focus:outline-none" @click="$emit('close')">
              Close
            </button>

          </div>
          <div class="relative">
            <div class="absolute inset-x-6 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300"></div>
            </div>
          </div>
          <div class="px-6 py-4 absolute top-32 bottom-10 overflow-auto"
               style="width: 95%">
            <div v-show="historyLoading" class="flex justify-center">
              <svg version="1.1" width="32" height="32" class="animate-spin" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                   viewBox="0 0 32 32" xml:space="preserve">
                  <g >
                    <path style="fill:#DB5461;" class="st0" d="M32,13.6c-0.4-2.6-1.4-5.2-3-7.2l-3.2,2.4c1.2,1.6,2,3.4,2.2,5.4C30.4,13.8,29.4,14,32,13.6z"
                    />
                    <path style="fill:#DB5461;" class="st0" d="M25.6,3.2c-2-1.6-4.6-2.8-7.2-3.2c-0.4,2.6-0.2,1.6-0.6,4c2,0.2,3.8,1,5.4,2.2L25.6,3.2z"/>
                    <path style="fill:#DB5461;" class="st0" d="M16,28C9.4,28,4,22.6,4,16C4,10,8.6,4.8,14.4,4l-0.6-4C6,1.2,0,8,0,16c0,8.8,7.2,16,16,16
                      c8,0,14.8-5.8,15.8-13.8l-4-0.6C27.2,23.4,22,28,16,28L16,28z"/>
                  </g>
                  </svg>
              <div class="animate-pulse p-1">LOADING HISTORY DATA...</div>
            </div>
            <slot>
              <ActivityItem v-show="!historyLoading" v-for="item in siteActivity" v-bind="{item: item}" :key="item.HistoryDate" >
              </ActivityItem>
              <button @click="$emit('click-history-refresh')" v-show="!historyLoading" class="flex justify-end bg-indigo-400 hover:bg-indigo-500 rounded text-white
                       mx-2 my-2 px-3 py-1 h-8 focus:outline-none">
                Load Next Page
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import ActivityItem from './ActivityItem'
  export default {
    props: ['siteActivity', 'historyLoading'],
    components: {
      ActivityItem
    },
    mounted: function () {
      window.addEventListener('scroll', function() {
        const div = this.$refs.activityContainer;
        if (div.scrollTop === (div.scrollHeight - div.offsetHeight)) {
          this.loadMoreActivity();
        }
      });
    },
    methods: {
      loadMoreActivity: function () {
        // get the timestamp of the last record and pass it into history function
        if (this.siteActivity && this.siteActivity.length > 0){
          // get the historyDate of the last record
          var lastRec = self.siteActivity[this.siteActivity.length - 1];
          var histDate = lastRec.historyDate;

        }
        console.log("Load More activity called.")
      }
    }
  }
</script>

<style>
  .activity-mask {
    z-index: 9998;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
  }
  .activity-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
  }
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>