<template>
  <div class="fixed bottom-32 right-0 uppercase p-2 h-10 w-10 bg-red-600 text-sm text-white text-center rounded-full
              mr-2 mb-2 shadow-md"
       v-on:click="$emit('click-new-message-indicator')">
    <svg class="fill-current text-white block m-auto w-6 h-6"
         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M 3 8 L 3 26 L 29 26 L 29 8 Z M 7.3125 10 L 24.6875 10 L 16
               15.78125 Z M 5 10.875 L 15.4375 17.84375 L 16 18.1875 L 16.5625
               17.84375 L 27 10.875 L 27 24 L 5 24 Z"/>
    </svg>
  </div>
</template>

<script>
  export default {
  }
</script>