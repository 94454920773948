<template>
  <div class="absolute bottom-0 left-0 right-0 p-1 h-32 w-full overflow-hidden
              justify-center bg-gradient-to-r from-gray-300 to-gray-100 border-t border-gray-400">
    <ActivityBar v-bind="{connectedContacts: connectedContacts,
                          chatColors: this.chatColors,
                          typingTimers: typingTimers}">
    </ActivityBar>
    <form id="message-form" @submit.prevent="sendMessage"
          class="text-center align-baseline"
          autocomplete="off" role="form" action="">
      <input ref="message" id="body" v-model="message" name="message" type="text"
             placeholder="Message"
             v-on:click="$root.$emit('click-message-text-field')"
             class="rounded-full w-11/12 mt-3 p-3 focus:outline-none
                    focus:shadow-outline pr-16 text-base"/>
      <button type="submit" class="w-16 h-16 p-2 align-middle
                                   focus:outline-none rounded-full -ml-16">
        <svg class="fill-current text-gray-800 block m-auto text-lg w-8 h-8"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path d="M 3.59375 5.34375 L 4.03125 7.21875 L 5.96875 16 L 4.03125 24.78125 L 3.59375
          26.65625 L 5.375 25.9375 L 27.375 16.9375 L 29.65625 16 L 27.375 15.0625 L 5.375 6.0625
          Z M 6.375 8.65625 L 21.90625 15 L 7.78125 15 Z M 7.78125 17 L 21.90625 17 L 6.375
          23.34375 Z"></path>
        </svg>
      </button>
    </form>
  </div>
</template>

<script>
  import axios from 'axios'
  import ActivityBar from './ActivityBar'
  export default {
    props: ['room', 'user', 'chatColors', 'connectedContacts', 'typingTimers'],
    data: function () {
      return {
        message: ''
      }
    },
    components: {
      ActivityBar
    },
    methods: {
      sendMessage: function() {
        if (this.message.trim().length <= 0) {
          this.clearInputAndFocus();
          return;
        }
        console.log("Sending message: " + this.message);
        const postUrl = '/c/' + this.room.site_num + '/' + this.user.num;
        axios.post(postUrl, {
          body: this.message,
          room_id: this.room.id,
          contact_id: this.user.id
        })
        this.clearInputAndFocus();
      },
      clearInputAndFocus: function() {
        this.message = '';
        this.$refs.message.focus();
      }
    }
  }
</script>